import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function Attend() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          // backgroundImage: "url(" + require("assets/images/bg.jpg") + ")",
          background: "#000000",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container className={""}>
          <Row className="justify-content-center">
            <Col md="12">
              <h1
                className=" text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                WHO CAN PARTICIPATE
              </h1>
            </Col>
            {content.map((c, i) => (
              <Col lg={4} xs={11} className="px-1">
                <Card
                  style={{
                    background: "rgba(0,0,0,0.4)",
                    height: size ? "100px" : "none",
                  }}
                  className="px-3"
                >
                  <div className="d-flex">
                    <div
                      className="px-2 align-self-center rounded-circle"
                      style={{
                        minWidth: 100,
                        height: 100,
                        background: i % 2 === 0 ? "#6ac2be" : "#00a4c3",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "5px solid #ffffff",
                      }}
                    >
                      <img
                        src={require(`assets/icons/${c.image}`)}
                        style={{ width: "60px", height: "60px" }}
                        alt="about"
                        className=""
                      />
                    </div>
                    <div
                      className="d-flex align-items-center w-100"
                      style={{
                        borderTop: "5px solid #ec33f3",
                        borderRight: "5px solid #ec33f3",
                        borderBottom: "5px solid #ec33f3",
                        borderImage: "linear-gradient(to right, darkblue, darkorchid) 1",
                        borderRadius: '30px',
                      }}
                    >
                      <p className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1 mt-5">
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
                style={{ background: "#20bdbe" }}
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  Register Now
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "PROPERTY DEVELOPERS", image: "Asset 702.png" },
  { title: "PROJECT OWNERS", image: "14.png" },
  { title: "ARCHITECTURE, ENGINEERING, CONTRACTORS", image: "Asset 682.png" },
  { title: "CONSTRUCTION MANAGEMENT CONSULTANTS", image: "Asset 742.png" },
  { title: "GIS LEADERS", image: "Asset 662.png" },
  { title: "GOVERNMENT BODIES & MUNICIPALITIES", image: "Asset 692.png" },
  { title: "IT LEADERS", image: "Asset 732.png" },
  { title: "CITY PLANNERS", image: "Asset 782.png" },
  { title: "FACILITY MANAGERS", image: "Asset 752.png" },
  { title: "DIGITAL TWIN SOLUTION PROVIDERS", image: "Asset 762.png" },
  { title: "METAVERSE OFFICERS", image: "Asset 672.png" },
  { title: "DATA MANAGEMENT LEADERS", image: "Asset 772.png" },
  { title: "BIM & DIGITAL CONSTRUCTION SOLUTION PROVIDERS", image: "Asset 722.png" },
];

export default Attend;
