import React from "react";

// core components
import Navbar from "../components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "../components/Footer.js";

import PastSpeakerCard from "../components/PastSpeakerCard.js";

function Home() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="PAST EDITION SPEAKERS" bgColor="#fff" />
      <PastSpeakerCard />
      <DemoFooter />
    </>
  );
}

export default Home;
