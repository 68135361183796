import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import Slider from "react-slick";
import { DataContext } from "DataContainer";

function Sponsor() {
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 2,
    rows: 2,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { pastSponsors: allSponsors } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-g mb-5"
            style={{
              fontSize: "3rem",
            }}
          >
            PAST EDITION PARTNERS
          </h1>

          <Row>
            <Col lg={12} className={"mx-auto"}>
              <Slider {...settings}>
                {allSponsors.map((s, i) => (
                  <a href={s.url} key={i} style={{ padding: "10px" }}>
                    {/* <h4 className="text-center text-400 text-i pb-2">{s.type}</h4> */}
                    <Card
                      style={{ borderRadius: 0, maxWidth: "350px", boxShadow: "none" }}
                      className="mx-auto"
                    >
                      <img
                        src={s.image}
                        className="mx-auto"
                        alt={s.name}
                        width="100%"
                        style={{ borderRadius: 0 }}
                      />
                    </Card>
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
