// reactstrap components
import React, { useEffect } from "react";

import Container from "reactstrap/lib/Container";

import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Card } from "reactstrap";

function Event({ text, location }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  useEffect(() => {
    const common = {
      containment: "#bgndVideo1",
      autoPlay: true,
      mute: true,
      startAt: 20,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "2tuhmrx0Ut0",
        ...common,
      },
    ];
    if (size) {
      window.jQuery("#bgndVideo1").YTPlaylist(videos, false);
    }
  }, []);

  return (
    <div className="section pt-4" style={{ backgroundColor: !size ? "#000" : "transparent" }}>
      {size && (
        <div
          style={{
            background: "#000",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: -100,
            marginLeft: "auto",
            marginRight: "auto",
            pointerEvents: "none",
            // marginTop: "3.5rem",
          }}
        >
          <div
            id="bgndVideo1"
            className="section player"
            style={{ background: "transparent" }}
          ></div>
        </div>
      )}
      {size && <div className="overlay-primary" />}
      <div
        className={size ? "page-header" : "section"}
        style={{ background: "transparent" }}
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Event Summary
              </h1>
            </Col>
            <Col md={6}>
              <p className="text-400 text-white">
                The UAE and Middle East region is now seeing Digital Twins move from
                conceptualization to implementation with key government and private projects within
                construction and smart cities announcing live projects using digital twin systems.
                <br />
                <br />
              </p>
            </Col>
            <Col md={6}>
              <p className="text-400 text-white">
                With the theme – From Vision to Reality, we are thrilled to announce the{" "}
                <span className="text-info text-700">
                  4th Annual DIGITAL TWIN 2025, Dubai on February 20 and Workshops on February 21,
                  2025.
                </span>
                <br />
                <br />
              </p>
            </Col>
            <Col lg={12}>
              <p className="text-400 text-white">
                Saifee Events is committed to continue the dialogue to raise awareness and
                innovations in Digital Twins for the Built Environment and Infrastructure sector
                globally. With our events in Dubai, Riyadh, London, Auckland, where each region is
                exploring Digital Twins, our events continue to be the ideal platform to share user
                case studies, new innovations, learn and network.
              </p>
            </Col>

            <Col md={12} className="pr-0">
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "1.7rem",
                }}
              >
                Conference Format
              </h1>

              <div className={`container-fluid row ${size ? "row-cols-5" : "row-cols-2"}`}>
                {content.map((c, i) => (
                  <Col>
                    <Card
                      style={{
                        background: "rgba(0,0,0,0)",
                      }}
                    >
                      <div
                        className="px-2 align-self-center rounded-circle"
                        style={{
                          width: 100,
                          height: 100,
                          background: i % 2 === 0 ? "#6ac2be" : "#00a4c3",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "5px solid #ffffff",
                        }}
                      >
                        <img
                          src={require(`assets/icons/${c.image}`)}
                          style={{ width: "60px", height: "60px" }}
                          alt="about"
                          className=""
                        />
                      </div>
                      <div
                        className="d-flex align-items-center w-100"
                        style={{ textAlign: size && "center" }}
                      >
                        <p className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Event;

const content = [
  {
    title: "VIP KEYNOTES",
    image: "14.png",
  },
  {
    title: "EXHIBITION & NETWORKING ",
    image: "15.png",
  },
  {
    title: "INTERNATIONAL CASE STUDIES AND PROOF OF CONCEPTS",
    image: "12.png",
  },
  {
    title: "THOUGHT-PROVOKING PANEL DISCUSSIONS",
    image: "11.png",
  },

  {
    title: "TECHNICAL-INTERACTIVE WORKSHOPS",
    image: "13.png",
  },
];
