import React from "react";
import { Row, Container, Col, Card } from "reactstrap";
import { FaEnvelope, FaLinkedinIn } from "react-icons/fa";

function DemoFooter() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <footer className="footer footer-black footer-white ">
      <Container fluid>
        <Row className="mb-2">
          <Col lg={1} xs={0} />
          <Col lg={4} style={{ paddingTop: "3rem" }}>
            <p>Organised By</p>
            <img
              src={require("assets/images/logo/3.png")}
              width="70%"
              alt="saifee"
              className="mx-auto"
              style={{ maxWidth: "150px" }}
            />
            <h5 className="text-400 pt-3">
              <br />
              <b>SAIFEE EVENTS FZCO</b>
              <br />
              Building A1, Dubai Digital Park, <br />
              Dubai Silicon Oasis, Dubai, UAE
              <br />
              {/* <br />
              <br />
              <b>SAIFEE EVENTS PVT LTD</b>
              <br />
              A-17/24, Kubera Park Kondhwa Road
              <br />
              Pune- 411048 MH, India
              <br />
              <br /> */}
              <br />
              <br />
            </h5>
          </Col>
          <Col lg={3} className={"text-left"} style={{ padding: "3rem" }}>
            <p>
              <a href="/" style={{ color: "#333333" }}>
                Home
              </a>
            </p>
            <p>
              <a href="/#about" style={{ color: "#333333" }}>
                About
              </a>
            </p>

            <p>
              <a href="https://saifee-events.com/privacy-policy" style={{ color: "#333333" }}>
                Privacy-Policy Saifee-Events
              </a>
            </p>
          </Col>
          <Col lg={4} style={{ padding: "3rem" }}>
            <p>Connect With Us</p>

            <a
              className={` text-900 px-2`}
              style={{
                color: "#02092c",
                fontSize: "30px",
              }}
              href="https://www.linkedin.com/company/saifee/?viewAsMember=true"
            >
              <FaLinkedinIn />
            </a>
            <a
              href={`mailto:huzefa@saifee-events.com`}
              style={{
                color: "#02092c",
                fontSize: "30px",
                marginLeft: "20px",
              }}
            >
              <FaEnvelope />
            </a>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center pt-10"}>
            <p>© {new Date().getFullYear()} Saifee-Events. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
