import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section py-5"
        style={{
          backgroundColor: "#000000",
        }}
      >
        <Container fluid>
          <Row className="justify-content-center">
            <Col className="ml-auto mr-auto text-dark" md={12}>
              <h1
                className="text-center text-g mb-5"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                KEY TOPICS
              </h1>
            </Col>
            <Col lg={12} className={size && "px-5"}>
              <div className="container-fluid row justify-content-center">
                {content.map((c) => (
                  <Col xs={12} lg={6} className={`my-2 ${!size && "px-0"}`}>
                    <Card
                      className="rounded-0"
                      style={{
                        background: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <img
                        src={require(`assets/icons/${c.image}`)}
                        width="100%"
                        alt="about"
                        // className="mx-auto"
                      />
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

const content = [
  {
    image: "Asset 5_6.png",
  },
  {
    image: "Asset 4_6.png",
  },
  {
    image: "Asset 3_6.png",
  },
  {
    image: "Asset 2_6.png",
  },
  {
    image: "Asset 1_6.png",
  },
];
