export const oldToken2 =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiOWY3MGZlMzQtN2Q0Yi0xMWVkLWExZWItMDI0MmFjMTIwMDAyIiwiY3VzdG9tZXJJZCI6ImQxYjkxMGZhLTMxYTgtMTFlZC1hMjYxLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL21lLmRpZ2l0YWx0d2luLXN1bW1pdC5jb20iLCJpYXQiOjE2NzEyMDIwMzMsImF1ZCI6ImV4cC5jb20iLCJpc3MiOiJleHAtcHJvdmlkZXIiLCJzdWIiOiJhZG1pbkBleHAuY29tIn0.N4x7VM9CvFf86X0MwL-t8EE2vawkqar79gAFZdEL3onrX_pGQlTw1UsQ_p9I8gqVoHeX1lIYWQM6pK9YXtMzR76KdlMoASNqI308rttRPFF6UTAH9biQwF2_mVzq5JstiBPkiu7wvV8ytrMet8XfM45W01rDW9nS3btEnhP7W-0";

export const apiUrl = "https://api.rapid-events.com";
// export const apiUrl = "http://localhost:3012";

export const oldToken =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiZDllMDMzMzItMWQ1NS0xMWVlLWJlNTYtMDI0MmFjMTIwMDAzIiwiY3VzdG9tZXJJZCI6ImQxYjkxMGZhLTMxYTgtMTFlZC1hMjYxLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL21lLmRpZ2l0YWx0d2luLXN1bW1pdC5jb20iLCJpYXQiOjE2OTgxNDYwOTIsImF1ZCI6ImV4cC5jb20iLCJpc3MiOiJleHAtcHJvdmlkZXIiLCJzdWIiOiJhZG1pbkBleHAuY29tIn0.Uda-erFGSPr2e3i1HKAVX_yLcgQQ2qH9kyiYqG2yE-FcGeeyhpIg1wXLTzwQq3cubOlMfdL4ZY5QACS6dkBumw6jjgkajvAd0UmptMUB_KCnS95Z2anLWmLeivlNwseBJjVSFIvo6laj3wEr_dAS__Ri8-icaRh82r8Ka-MkQgo";

export const apiToken =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiOWY3MGZlMzQtN2Q0Yi0xMWVkLWExZWItMDI0MmFjMTIwMDIyIiwiY3VzdG9tZXJJZCI6ImQxYjkxMGZhLTMxYTgtMTFlZC1hMjYxLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL21lLmRpZ2l0YWx0d2luLXN1bW1pdC5jb20iLCJpYXQiOjE3MzEwNzUwMDYsImF1ZCI6ImV4cC5jb20iLCJpc3MiOiJleHAtcHJvdmlkZXIiLCJzdWIiOiJhZG1pbkBleHAuY29tIn0.XjalPMIUaqxX-qHUvhW9gdOLS-kOsTyhNCYyPzrklooU2Bxkqbps4PTkZigUOKmwEBWVYNih0ElAxsUu8m85sYOztXj6qecLL9LJShlMHKKbXT3SEAwWBHow6HRfl0beyCjeCa-ClWhPXWwtPRO4JLA76bmbKsAl2vv-siiseWA";
