import React from "react";

// core components
import Navbar from "../components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "../components/Footer.js";
import PastEventHighlights1 from "../components/PastEventHighlights1";
import Sponsor from "../components/Sponsor.js";
import Photos from "../components/Photos";

function Home() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="PAST EVENT  HIGHLIGHTS" bgColor="#fff" />
      <PastEventHighlights1 />
      <Photos />
      <Sponsor />
      <DemoFooter />
    </>
  );
}

export default Home;
