import React from "react";

// core components
import Navbar from "../components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "../components/Footer.js";

import Attend from "../components/Attend.js";

function Home() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="Who Should Attend" bgColor="#fff" />
      <Attend />
      <DemoFooter />
    </>
  );
}

export default Home;
