import React, { useEffect } from "react";

function RegisterPage() {
  useEffect(() => {
    window.location.href =
      "https://drive.google.com/uc?id=16stU4DzVNMdtzw4oUpsIxzocYQ-T3PW7&export=download";
  }, []);
  return <></>;
}

export default RegisterPage;
