import React from "react";
import { Container, Row, Col } from "reactstrap";

function Editions() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div
      style={{
        backgroundColor: "#000000",
      }}
      className="section"
      data-parallax={true}
      ref={pageHeader}
    >
      <div className="overlay-primary"></div>
      <Container>
        <Row>
          <Col lg={12} className=" mb-5">
            <h1
              className="text-center text-g"
              style={{
                fontSize: "3rem",
              }}
            >
              GLOBAL EDITIONS
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center mb-5">
          {events.map((e) => (
            <Col lg={4} xs={12} className={`mb-4 text-center`}>
              <a href={e.link}>
                <img
                  src={e.logo}
                  style={{ maxWidth: size ? "450px" : "250px" }}
                  width="100%"
                  alt="dt22"
                />
                <h3 className="text-400 mt-0 text-white text-center mt-3" style={{}}>
                  {e.location}
                  <br />
                  {e.date}
                </h3>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Editions;

const events = [
  {
    logo: require("assets/editions/eg.jpg"),
    location: "Cairo, Egypt",
    date: "",
    link: "https://eg.digitaltwin-summit.com",
  },
  {
    logo: require("assets/editions/nz.jpg"),
    location: "Auckland, New Zealand",
    date: "",
    link: "https://nz.digitaltwin-summit.com",
  },
  {
    logo: require("assets/editions/saudi.jpg"),
    location: "Riyadh, Saudi Arabia",
    date: "",
    link: "https://ksa.digitaltwin-summit.com",
  },
  {
    logo: require("assets/editions/uk.jpg"),
    location: "London, United Kingdom",
    date: "",
    link: "https://uk.digitaltwin-summit.com",
  },
];
