import Particles from "react-tsparticles";
import React from "react";

function ParticleBackground() {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "2000px",
      }}
    >
      {/* <Particles
				params={{
					particles: {
						number: {
							value: 10,
						},
						size: {
							value: 3,
						},
						color: {
							value: '#bfbfbf',
						},
						line_linked: {
							color: '#bfbfbf',
						},
					},
					
					interactivity: {
						events: {
							onhover: {
								enable: true,
								mode: 'bubble',
							},
						},
						modes: {
							bubble: {
								size: 6,
								distance: 40,
							},
						},
					},
				}}
			/> */}
      <Particles
        id="tsparticles"
       
        options={{
         
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 5,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#afafaf",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed:1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      )
    </div>
  );
}

export default ParticleBackground;
